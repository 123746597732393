exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-js": () => import("./../../../src/pages/custom.js" /* webpackChunkName: "component---src-pages-custom-js" */),
  "component---src-pages-fleet-managers-js": () => import("./../../../src/pages/fleet-managers.js" /* webpackChunkName: "component---src-pages-fleet-managers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individuals-js": () => import("./../../../src/pages/individuals.js" /* webpackChunkName: "component---src-pages-individuals-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-request-a-demo-js": () => import("./../../../src/pages/request-a-demo.js" /* webpackChunkName: "component---src-pages-request-a-demo-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

